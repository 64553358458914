import React from 'react';

function Welcome() {

    return (
        <div className="container mx-auto max-xl">
            <img className="inline-block m-4" src="images/open-path-logo-only.png"/>
            <div className="pt-8 inline-block">
                <h1 className="text-3xl font-bold inline-block">
                    Open Path Consulting
                </h1>
            </div>

            <div className="bg-gray-600 p-4">
                &nbsp;
                <span className="text-white float-right">NORMAN.MURRIN@GMAIL.COM</span>
            </div>

            <div className="py-16" style={ {
                // 'minHeight': '100vh',
                'backgroundSize': 'cover',
                'backgroundRepeat': 'no-repeat',
                'backgroundPosition': 'center center',
                'backgroundImage': 'url(/images/cairn.jpg)',
                'backgroundAttachment': 'fixed'
            } }>
                <div className="mx-auto max-w-3xl">
                    <h2 className="text-6xl font-extrabold tracking-widest text-white uppercase my-2">
                    Open Path Consulting Welcomes You
                    </h2>
                    <h3 className="font-serif text-4xl font-bold text-white italic my-2">
                    Lets Build Something Together
                    </h3>
                    <span className="font-serif font-bold text-white my-2">
                    Open Path Consulting offers services focused on developing and deploying scalable software solutions to cloud platforms. We adhere to agile and extreme programming methodologies to develop sustainable, high quality software. Contact us to learn more.​
                    </span>
                </div>
            </div>

            <div className="container max-w-4xl mx-auto py-8">
                <div className="columns-2 h-full m-4">
                    <div className="mt-20">
                        <h3 className="text-2xl font-bold tracking-widest uppercase my-2">
                            Software Development
                        </h3>
                        <div className="font-serif italic my-2">
                            Build it Right, Deploy it Right
                        </div>
                        <div className="font-serif my-2">
                            Open Path software developers deliver optimal solutions and customer value. This means delivering cloud native applications on architectures including AWS, Azure and Pivotal Cloud Foundry.
                        </div>
                        <div className="font-serif my-2">
                            We are focused on developing using Java / Kotlin / Spring along with modern JavaScript libraries like Angular and React. Our consultants think cloud first and are adept at designing loosely coupled, microservices based architectures.
                        </div>
                    </div>
                    <img
                        className="m-4"
                        src="/images/communication-tower.jpg"
                    />
                </div>
            </div>

            <div className="container max-w-4xl mx-auto py-8">
                <div className="columns-2 m-4">
                    <div className="mt-20">
                        <h3 className="text-2xl font-bold tracking-widest uppercase my-2">
                            Agile / Extreme Programming
                        </h3>
                        <div className="font-serif italic my-2">
                            Be More Productive and Have Fun
                        </div>
                        <div className="font-serif my-2">
                            Software development is increasingly more important to businesses success. It's crucial that companies software development efforts are productive. Agile and Extreme Programming methodologies focus development hours on addressing the most critical business needs and designing sustainable, high quality software.
                        </div>
                        <div className="font-serif my-2">
                            In addition to providing developers who are comfortable with these methodologies, Open Path services include:
                            <ul>
                                <li>- XP Roadmap</li>
                                <li>- XP Training</li>
                            </ul>
                        </div>
                    </div>
                    <img
                        className="m-4"
                        src="/images/students-typing.jpg"
                    />
                </div>
            </div>

            <div className="py-16 bg-sky-100">
                <div className="container max-w-4xl mx-auto py-8">
                    <div className="columns-2 m-4">
                        <h2 className="text-5xl font-extrabold tracking-widest uppercase my-2">
                            Contact Us
                        </h2>
                        <div>
                            <p className="font-serif font-bold my-2">
                                Contact us and find how our services can benefit your company.
                            </p>
                            <p className="font-serif font-bold my-2">
                            norman.murrin@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Welcome;
