import './App.css';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Welcome from './components/Welcome';

function App() {
  return (
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={ <Welcome/> }/>
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
